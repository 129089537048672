.staff-form-modal {
    height: calc(100vh - 20px); /* Fullscreen with breathing room */
  
    .modal-header {
      background-color: #C9150E; /* Match Sider color */
      padding: 20px;
      color: white;
  
      .modal-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .modal-title {
          font-size: 24px;
          font-weight: bold;
          color: white;
        }
  
        .modal-tabs {
          display: flex;
          gap: 10px;
  
          .tab-btn {
            background: none;
            color: white;
            border: none;
            padding: 10px 15px;
            font-size: 16px;
            cursor: pointer;
  
            &:hover,
            &.active {
              background-color: #a0100b; /* Darken Sider color on hover/active */
            }
          }
        }
      }
    }
  
    .form-footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;
  
      .cancel-btn {
        background-color: #f0f2f5;
        color: #333;
        border: 1px solid #d9d9d9;
      }
  
      .save-btn {
        background-color: #C9150E;
        border-color: #C9150E;
        color: white;
      }
    }
  }
  