.class-list-container {
  padding: 20px;

  h2 {
    text-align: left;
  }
}

.table-section {
  margin-bottom: 30px;

  .table-header {
    background-color: #C9150E; /* Match the Sider color */
    padding: 10px 20px;
    color: white;
    margin-bottom: 0;
    /* Removed rounded corners */
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .class-table {
    .ant-table-thead {
      display: none; /* Hide the table header row */
    }

    .ant-table-tbody > tr > td {
      border-bottom: none; /* Remove grey bottom lines */
    }

    .ant-table {
      border: none; /* Remove table outer border */
    }
  }

  .actions-button {
    color: #C9150E;
  }

  .clock-in-button {
    background-color: #C9150E;
    border-color: #C9150E;
    color: white;
    &:hover {
      background-color: darken(#C9150E, 10%);
    }
  }

  .check-in-button {
    background-color: #C9150E;
    border-color: #C9150E;
    color: white;
    &:hover {
      background-color: darken(#C9150E, 10%);
    }
  }
}
