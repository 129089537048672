.student-list-container {
    padding: 20px;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .table-title {
    font-size: 20px;
    font-weight: bold;
  }
  
  .student-list-table .ant-table-thead > tr > th {
    background-color: #C9150E;
    color: white;
    font-weight: bold;
  }
  
  .actions-button {
    color: #C9150E;
  }
  