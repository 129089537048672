.dashboard-layout {
    height: 100vh;
  }
  
  .dashboard-sider {
    background-color: #C9150E; /* Red color for the sider */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .sider-logo {
    width: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-image {
    max-width: 50%;
    height: auto;
    max-height: 33%;
    object-fit: contain;
  }
  
  .dashboard-menu {
    background-color: #C9150E; /* Match menu background with sider */
    color: white;
    border-right: none;
  }
  
  .dashboard-menu .ant-menu-item,
  .dashboard-menu .ant-menu-submenu-title {
    color: white; /* Menu text color */
  }
  
  .dashboard-menu .ant-menu-item:hover,
  .dashboard-menu .ant-menu-item-selected,
  .dashboard-menu .ant-menu-submenu-title:hover,
  .dashboard-menu .ant-menu-submenu-title-selected {
    background-color: #a0100b; /* Slightly darker red for hover/selected state */
    color: white;
  }
  
  .dashboard-menu .ant-menu-submenu-arrow::before,
  .dashboard-menu .ant-menu-submenu-arrow::after {
    background-color: white;
  }
  
  .dashboard-content {
    padding: 20px;
    background-color: #f0f2f5;
  }
  