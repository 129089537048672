.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-card {
    width: 33%; /* 1/3 of the screen width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  