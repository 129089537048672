.student-form-modal {
  height: 50%;
  width: 90vw;

  .modal-header {
    background-color: #C9150E;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: 24px;
      font-weight: bold;
      color: white;
    }

    .modal-tabs {
      display: flex;
      gap: 15px;

      .tab-btn {
        background: none;
        color: white;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: #a0100a;
        }
      }
    }
  }

  .student-form {
    padding: 20px;
    overflow-y: auto;

    .form-footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;

      .cancel-btn {
        background-color: #f0f2f5;
        color: #333;
        border: 1px solid #d9d9d9;
      }

      .save-btn {
        background-color: #C9150E;
        border-color: #C9150E;
        color: white;
      }
    }
  }
}
