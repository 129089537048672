.staff-list-container {
    //background-color: white;
    padding: 20px;
    //border-radius: 8px;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
  
      .table-title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
  
      .ant-btn {
        color: white;
        background-color: #c9150e;
        border: none;
  
        &:hover {
          background-color: darken(#c9150e, 10%);
        }
      }
    }
  
    .staff-list-table {
      .ant-table-thead > tr > th {
        background-color: #c9150e;
        color: white;
        font-weight: bold;
      }
  
      .ant-table-tbody > tr:hover > td {
        background-color: #f5f5f5;
      }
    }
  }
  